import React, { useState, useRef, useEffect } from 'react'
import '../assets/sass/style.scss'
import { tagImageNames } from '../assets/lib/constants'
import WorkDetailModal from './WorkDetailModal'
import { Link, navigate } from 'gatsby'

const WorkDetailContent = (props) => {
  const { workDetailData: work } = props

  const ref = useRef()

  const [ isShown, setIsShown ] = useState(false)

  const imageLoaded = () => {
    setIsShown(true)
  }

  // if eyechatchImage is specified in work-data.json,
  // display eyecatchImage and if not, display same image as works list
  let eyecatchImage = ''
  try {
    eyecatchImage = work.eyecatchImage.childImageSharp.fluid.src
  } catch(error) {
    if (work?.image?.size > 1000000) {
      eyecatchImage = work.image.childImageSharp.fluid.src;
    } else if (work?.image) {
      eyecatchImage = work.image.publicURL;
    }
  }

  useEffect(() => {
    if (!ref.current.complete && !isShown) {
      setIsShown(true)
    }
  }, [isShown])

  const onClickedModalContent = (event) => {
    // prevent closing modal when clicked content
    event.stopPropagation()
  }

  const filterWorkContent = work?.html?.replace(/<li>\n<p>/g,"<li>").replace(/<\/p>\n<ul>/g,"<ul>");

  // propsオブジェクトからisTransitionEnabledとsetIsTransitionEnabledを取り出す
  const { isTransitionEnabled, setIsTransitionEnabled } = props;

  // リンクがクリックされたときに呼び出される関数を定義
  const handleLinkClick = (e) => {
    setIsTransitionEnabled(false); // トランジションを無効にする
  };

  const closeModal = () => {
    setIsTransitionEnabled(true); // トランジションを有効にする
    navigate('/works')
  }

  return (
    <WorkDetailModal onClickedCloseButton={closeModal} type="detail" content=
      {
        <div className={`${isTransitionEnabled ? "work-detail work-detail--transition" : "work-detail"} js-modal-contents ${isShown ? 'work-detail--show' : ''}`} onClick={onClickedModalContent}>
          <div className="work-detail__upper-container">
            <h1 className="work-detail__name">{ work?.name }</h1>
            <p className="work-detail__company">{ work?.company }</p>
            <p className={`work__tag work__tag--${ work?.tag }`}>{ tagImageNames[work?.tag]?.jp }</p>
            {work?.releasedDate && (
              <p className="work-detail__release-date">公開日: { work?.releasedDate }</p>
            )}
            {work?.url && (
            <p className="work-detail__url">URL: <a className="work-detail__url-link--blue" href={`https://${ work?.url }`} target="_blank" rel="noreferrer">{ work?.url }</a></p>
            )}
            <div className="work-detail__category-image-container">
              <img className="work-detail__category-image" src={`../../image/common/services/ico_service_${ tagImageNames[work?.tag]?.en }.svg`} alt={work?.name} ref={ref}/>
            </div>
          </div>
          <div className="work-detail__image-container">
            <img className="work-detail__image" src={eyecatchImage} alt="" onLoad={imageLoaded}/>
          </div>
          <div className="work-detail__body-container">
            <div dangerouslySetInnerHTML={{__html: filterWorkContent}}></div>
          </div>
          {work?.voice_file && ( // work.voice_fileがnullでない場合に表示
            <div class="work-detail__button-container">
              <Link
                className="btn-blue"
                to={'/works/' + work?.file + '/voice'}
                onClick={handleLinkClick}
              >
                お客様の声
              </Link>
            </div>
            )}
        </div>
      }
    />
  )
}

export default WorkDetailContent